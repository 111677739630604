import $ from 'jquery';

export default {
	init() {
		$('html').addClass('js');

		$('.sd-testimonial').matchHeight(true);

		$('.stat-title').matchHeight({ property: 'min-height' });
		
		$( window ).load(function() {
			setTimeout(function() { 
				$('#home-what-we-do .mh-title').matchHeight(true);
				$('#home-what-we-do .mh-image').matchHeight(true);

				$('.sd-testimonial').matchHeight(true);
			}, 500);
		});

		var widest = 0;
		$(".fusion-checklist.align-center .fusion-li-item-content").each(function () { widest = Math.max(widest, $(this).outerWidth()); }).width(widest);
		
		
		$('.fusion-posts-container .fusion-post-wrapper').matchHeight({ property: 'min-height' });

		window.onload = () => {
			$('#wrapper .related-posts .fusion-carousel-item-wrapper').matchHeight(true);
		};
		

		// Blog pagination, fix so that there is a previous and next
		$('.pagination:not(:has(.pagination-prev))').each(function(){
			$('<span class="pagination-prev"><span class="page-prev disabled"></span></span>')
			  .prependTo(this);
		  });
		
		  $('.pagination:not(:has(.pagination-next))').each(function(){
			$('<span class="pagination-next"><span class="page-next disabled"></span></span>')
			  .appendTo(this);
		  });


		 /*  (function($) {
			$(document).off('click', '.facetwp-type-checkboxes .facetwp-checkbox:not(.disabled)');
			$(document).on('click', '.facetwp-type-checkboxes .facetwp-checkbox:not(.disabled)', function() {
			  var is_checked = $(this).hasClass('checked');
			  $(this).closest('.facetwp-facet').find('.facetwp-checkbox').removeClass('checked');
			  is_checked ? $(this).removeClass('checked') : $(this).addClass('checked');
			  FWP.autoload();
			});
		  })(jQuery); */

	},
};
